





































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class Config extends Vue {
  private isLoading = false
  private enableCustomerSync = false
  private enableServiceSync = false
  private partnerNumber = ''
  private vendorNo = ''
  private valid = false
  private environment = 'PRODUCTION'
  private defaultTyreHotelServiceCode = ''
  private rules = {
    partnerNumber: null,
    serviceCode: null,
  }

  private environments = [
    { id: 'DEVELOPMENT', name: 'Development' },
    { id: 'PRODUCTION', name: 'Production' },
  ]

  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  public created(): void {
    this.rules = {
      partnerNumber: [(v) => !!v || this.$t('c:validation:This field is required')],
      serviceCode: [(v) => !v || v.match(/^[a-zA-Z0-9]+$/) || this.$t('c:validation:Must only contain letters and numbers')],
    }
  }

  public mounted(): void {
    this.isLoading = true
    this.$axios
      .get('/v4/site/integrations/dackia')
      .then((response) => {
        this.populate(response.data.data || {})
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load settings')
        this.isLoading = false
      })
  }

  private save(): void {
    if (!this.$refs.form.validate()) {
      return
    }
    this.isLoading = true
    const data = {
      partnerNumber: this.partnerNumber,
      vendorNo: this.vendorNo,
      enableCustomerSync: this.enableCustomerSync,
      enableServiceSync: this.enableServiceSync,
      environment: this.environment,
      defaultTyreHotelServiceCode: this.defaultTyreHotelServiceCode,
    }
    this.$axios
      .post('/v4/site/integrations/dackia', data)
      .then((response) => {
        this.populate(response.data.data)
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving config')
        this.isLoading = false
      })
  }

  private populate(data): void {
    this.partnerNumber = data.partnerNumber as string
    this.vendorNo = data.vendorNo as string
    this.enableCustomerSync = data.enableCustomerSync as boolean
    this.enableServiceSync = data.enableServiceSync as boolean
    this.environment = data.environment as string
    this.defaultTyreHotelServiceCode = data.defaultTyreHotelServiceCode as string
  }
}
